<template>
  <div>
    <About
        :aboutInfo="aboutInfo"
        :titleMoved="titleMoved"
    />
    <CasesTabs />
  </div>
</template>

<script>
import About from "@/components/About";
import CasesTabs from "@/components/CasesTabs";

export default {
  name: "CasesPage",
  components: {CasesTabs, About},
  data() {
    return {
      titleMoved: "CasesPage-Title",
      aboutInfo: {
        title: "<span>KEYWORD boost & USER</span> <span>Acquisition cases</span>",
        text: "If you need more detail"
      },
    }
  }
}
</script>

<style lang="scss">
.About-Title.CasesPage-Title {
  &:before {
    top: 33px;
    left: 0;
    @media (min-width: $screen-l) {
      top: 33px;
      left: -665px;
    }
  }
  &:after {
    width: 100%;
    height: 53px;
    top: 75px;
    left: 0;
    @media (min-width: $screen-l) {
      width: 781px;
      top: 70px;
      left: 65px;
    }
  }
}
</style>
<template>
  <div class="CasesTabs">
    <div class="Container">
      <div class="CasesTabs-Btns">
        <div class="CasesTabs-Btn"
            v-for="(button) in buttons"
            :key="button.id"
        >
          <input :id="button.type"
                 class="CasesTabs-Input"
                 type="radio"
                 v-model="selectedCategory" :value="button.type"
          >
          <label class="CasesTabs-Label"
                 :for="button.type">
            {{ button.name }}
          </label>
        </div>
      </div>
      <div class="CasesTabs-Tabs">
        <div class="CasesTabs-TabCard"
             v-for="info in filteredPeople"
             :key="info.id"
        >
          <div class="CasesTabs-TabCardAbout">
            <img :src="require(`@/assets/images/${info.icon}`)"
                 alt="icon"
                 class="CasesTabs-TabCardIcon">
            <div class="CasesTabs-TabCardInfo">
              <div class="CasesTabs-TabCardType">{{ info.category }}</div>
              <div class="CasesTabs-TabCardTitle">{{ info.title }}</div>
            </div>
          </div>
          <p class="CasesTabs-TabCardText" v-html="info.text"></p>
          <div class="CasesTabs-TabCardStatistics">
            <div class="CasesTabs-TabCardStatistic"
                 v-for="stat in info.statistic"
                 :key="stat.id"
            >
              <div class="CasesTabs-TabCardStatisticNumber">
                <img src="../assets/images/arrow-up.svg" alt="">
                {{ stat.number }}
              </div>
              <div class="CasesTabs-TabCardStatisticTitle">{{ stat.title }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--    <div class="Container" id="people">
          <div class="filter">
            <label><input type="radio" v-model="selectedCategory" value="All" /> All</label>
            <label><input type="radio" v-model="selectedCategory" value="Tech" /> Tech</label>
            <label><input type="radio" v-model="selectedCategory" value="Entertainment" /> Entertainment</label>
            <label><input type="radio" v-model="selectedCategory" value="Fictional" /> Fictional</label>
          </div>

          <ul class="people-list">
            <li v-for="person in filteredPeople" :key="person.name">{{ person.name }}</li>
          </ul>
        </div>-->
  </div>
</template>

<script>
export default {
  name: "CasesTabs",
  data() {
    return {
      buttons: [
        {name: "ALL CASES", type: "all", isActive: true},
        {name: "USER ACQUISITION", type: "user", isActive: false},
        {name: "KEYWORD BOOST", type: "keyword", isActive: false}
      ],
      cardInfo: [
        {
          icon: "onetwotrip-ic.svg",
          type: "keyword",
          category: "Keyword Boost",
          title: "ONETWOTRIP",
          text: "Boost and hold to the TOP 1 by 25+ keywords",
          statistic: [
            {number: "1350%", title: "Organic growth"},
            {number: "367k", title: "Organic users"},
            {number: "$0.4", title: "Per organic install"},
          ]
        },
        {
          icon: "aliexpress.png",
          type: "user",
          category: "User Acquisition",
          title: "Aliexpress",
          text: "Attracted huge volumes of quality traffic",
          statistic: [
            {number: "3.9M", title: "Organic users"},
            {number: "5%", title: "CR to purchase"},
          ]
        },
        {
          icon: "amediateka.svg",
          type: "keyword",
          category: "Keyword Boost",
          title: "Amediateka",
          text: "Returning the main keywords to the App Store index and attracting new paying users.",
          statistic: [
            {number: "145%", title: "Organic growth"},
            {number: "1250", title: "Organic users"},
            {number: "$0.8", title: "Per organic install"},
          ]
        },
        {
          icon: "joom.png",
          type: "user",
          category: "User Acquisition",
          title: "Joom",
          text: "Attracted huge volumes of paying users",
          statistic: [
            {number: "3.5M", title: "Organic users"},
            {number: "6%", title: "CR to purchase"},
          ]
        },
        {
          icon: "bookmate.png",
          type: "user",
          category: "User Acquisition",
          title: "Bookmate",
          text: "Increased the number of paying users by 5 times within 7 months",
          statistic: [
            {number: "1M", title: "Organic users"},
            {number: "7%", title: "CR to subscription"},
          ]
        },
        {
          icon: "frozen.png",
          type: "keyword",
          category: "Keyword Boost",
          title: "Frozen",
          text: "Increased reach by keywords in App Store due to text ASO",
          statistic: [
            {number: "480%", title: "Organic growth"},
            {number: "167k", title: "Organic users"},
            {number: "$0.25", title: "Per organic install"},
          ]
        },
        {
          icon: "patephone.png",
          type: "user",
          category: "User Acquisition",
          title: "Patephone",
          text: "Increased app revenue by 20 times. Patephone was included into TOP 10 The Best Apps 2016 according to Apple",
          statistic: [
            {number: "1.2M", title: "Organic users"},
            {number: "9%", title: "CR to trial"},
          ]
        },
        {
          icon: "poker.png",
          type: "keyword",
          category: "Keyword Boost",
          title: "Poker by Kama Games",
          text: "Boost and hold to the TOP 1-2 by 55 relevant keywords",
          statistic: [
            {number: "180%", title: "Organic growth"},
            {number: "27k", title: "Organic users"},
            {number: "$0.2", title: "Per organic install"},
          ]
        },
        {
          icon: "uralair.png",
          type: "keyword",
          category: "Keyword Boost",
          title: "Ural Airlines",
          text: "Boost and hold to the TOP 1-5 by 84 relevant keywords",
          statistic: [
            {number: "220%", title: "Organic growth"},
            {number: "58k", title: "Organic users"},
            {number: "$0.25", title: "Per organic install"},
          ]
        },
        {
          icon: "joom.png",
          type: "keyword",
          category: "Keyword Boost",
          title: "Joom",
          text: "Boost and hold to the TOP 1-5 by 177 keywords",
          statistic: [
            {number: "20%", title: "Organic growth"},
            {number: "24k", title: "Organic users"},
            {number: "$0.08", title: "Per organic install"},
          ]
        },
        {
          icon: "malificent.png",
          type: "keyword",
          category: "Keyword Boost",
          title: "MALEFICENT FREE FALL",
          text: "Attracting loyal organic users who play the games \"match 3\"",
          statistic: [
            {number: "270%", title: "Organic growth"},
            {number: "$0.5", title: "Organic users CPI"},
          ]
        },
        {
          icon: "starwars.png",
          type: "keyword",
          category: "Keyword Boost",
          title: "STAR WARS: COMMANDER",
          text: "Attracting loyal organic users who play the games \"strategy\"",
          statistic: [
            {number: "150%", title: "Organic growth"},
            {number: "$0.7", title: "Organic users CPI"},
          ]
        }, {
          icon: "islandexperiment.png",
          type: "keyword",
          category: "Keyword Boost",
          title: "ISLAND EXPERIMEN",
          text: "Increased organic users in Germany due to boosting the app to the TOP by keywords in App Store",
          statistic: [
            {number: "200%", title: "Organic growth"},
            {number: "$0.8", title: "Organic users CPI"},
          ]
        },
        {
          icon: "patephone.png",
          type: "keyword",
          category: "Keyword Boost",
          title: "Patephone Audiobooks",
          text: "Boost and hold by 3 keywords of “audiobooks” field",
          statistic: [
            {number: "350%", title: "Organic growth"},
            {number: "$0.5", title: "Organic users CPI"},
          ]
        },
        {
          icon: "pure.png",
          type: "keyword",
          category: "Keyword Boost",
          title: "Pure",
          text: "Boosting to the TOP by the most relevant keyword in Great Britain and holding within 20 days",
          statistic: [
            {number: "200%", title: "Organic growth"},
            {number: "$0.9", title: "Organic users CPI"},
          ]
        },
      ],
      selectedCategory: "all"
    }
  },
  computed: {
    filteredPeople: function () {
      let category = this.selectedCategory;

      if (category === "all") {
        return this.cardInfo;
      } else {
        return this.cardInfo.filter(function (info) {
          return info.type === category;
        });
      }
    }
  }
}
</script>

<style scoped lang="scss">
.CasesTabs {
  padding: 40px 0;

  @media (min-width: $screen-l) {
    padding: 40px 0 140px;
  }

  &-Btns {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 22px;

    @media (min-width: $screen-l) {
      flex-direction: row;
      margin-bottom: 62px;
    }
  }

  &-Btn {

    @media (min-width: $screen-l) {
      border: 3px solid var(--color-text-light);

      &:nth-child(2) {
        border-left: none;
        border-right: none;
      }
    }
  }

  &-Label {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 326px;
    padding: 12px;
    cursor: pointer;
    font-family: 'Lexend', sans-serif;
    font-weight: 500;
    font-size: 22px;
    line-height: 1.69em;
    text-transform: uppercase;
    color: var(--color-text-light);
    transition: .3s;

    &:hover,
    &:focus {
      background-color: var(--color-text-light);
      color: var(--color-text-main3);
    }
  }

  &-Input {
    display: none;
    position: absolute;
    visibility: hidden;
    z-index: -9999;
    left:-9999;
    right: -9999;
  }

  &-Tabs {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    @media (min-width: $screen-xl) {
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 23px 22px;
    }
  }

  &-TabCard {
    width: 100%;
    margin: 10px;
    padding: 29px 25px 25px;
    background-color: var(--color-text-main3);
    box-shadow: var(--color-shadow);
    font-family: 'Lexend', sans-serif;

    @media (min-width: $screen-xs) {
      position: relative;
      width: 420px;
      height: 407px;
    }

    @media (min-width: $screen-xl) {
      margin: 0;
    }
  }

  &-TabCardAbout {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 25px;
    text-align: center;

    @media (min-width: $screen-xs) {
      flex-direction: row;
      text-align: left;
    }
  }

  &-TabCardIcon {
    margin-bottom: 21px;

    @media (min-width: $screen-xs) {
      margin-right: 21px;
      margin-bottom: 0;
    }
  }

  &-TabCardType {
    font-weight: 500;
    font-size: 20px;
    line-height: 1.69em;
    text-transform: capitalize;
    color: var(--color-text-light);
  }

  &-TabCardTitle {
    font-weight: 500;
    font-size: 30px;
    line-height: 1.69em;
    text-transform: uppercase;
    color: var(--color-text-main);
  }

  &-TabCardText {
    margin-bottom: 20px;
    padding-right: 17px;
    font-family: 'Questrial', sans-serif;
    font-weight: 400;
    font-size: 20px;
    line-height: 1.69em;
    color: var(--color-text-main);

    @media (min-width: $screen-xs) {
      margin-bottom: 0;
    }
  }

  &-TabCardStatistics {

    @media (min-width: $screen-xs) {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      position: absolute;
      right: 25px;
      left: 25px;
      bottom: 25px;
      text-align: center;
    }
  }

  &-TabCardStatistic {
    margin-bottom: 10px;
    font-family: 'Lexend', sans-serif;
    line-height: 1.69em;

    &:last-child {
      margin-bottom: 0;
    }

    @media (min-width: $screen-xs) {
      margin-bottom: 0;
      text-align: center;
    }
  }

  &-TabCardStatisticNumber {
    font-weight: 600;
    font-size: 25px;
  }

  &-TabCardStatisticTitle {
    font-weight: 400;
    font-size: 14px;
    color: var(--color-text-ghost);
  }

  input:checked + label {
    background-color: var(--color-text-light);
    color: var(--color-text-main3);
  }
}
</style>
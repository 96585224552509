<template>
<section class="NotFound">
  <div class="Container">
    <h1 class="Title NotFound-Title">
      The page is not found
    </h1>
    <div class="NotFound-Text">
      404
    </div>
  </div>
</section>
</template>

<script>
export default {
  name: "Page404"
}
</script>

<style scoped lang="scss">
.NotFound {
  padding: 200px 0;
  text-align: center;

  &-Title {
    margin-bottom: 100px;
    &:before {
      width: 825px;
    }
  }

  &-Text {
    font-family: 'Lexend', sans-serif;
    font-weight: 700;
    font-size: 200px;
    line-height: 1.69em;
    color: var(--color-text-light);
  }
}
</style>